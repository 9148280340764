@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: 'Roboto Mono';
  overflow-x: hidden;
}

h2 {
  font-weight: 200;
}

li:focus {
  outline: none;
}

li::-moz-focus-outer {
  border: none;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background-color: rgb(20, 27, 31);
  color: white;
}


@media screen and (min-width: 1600px) {
  .App {
    justify-content: center;
  }

  .footer {
    position: absolute;
    bottom: 0;
  }
}

a {
  color: #bf6d61;
  transition: all 0.25s;
}

a:hover {
  opacity: 0.6;
}

.imageNext {
  font-family: 'Roboto Mono';
  margin: auto;
  width: 100%;
  background: transparent;
  border: none;
}

.footer {
  padding-top: 50px;
  opacity: 0.5;
}

.Name-title {
  letter-spacing: 0.5em;
  font-weight: lighter;
}

.Menu {
  text-align: center;
  margin: 0;
  padding: 0;
}

.Menu li {
  display: inline;
}

.Container {
  display: flex;
  max-width: 80%;
  margin: auto;
  /* min-height: 100vh; */
  align-items: center;
}

.buttonBack {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.buttonNext {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.dotGroup {
  text-align: center;
  display: none;
}

.dotGroup button {
  background-color: #d3aa92;
  border-radius: 10px;
  border: none;
  width: 20px;
  margin: 0 10px;
  height: 20px;
  transform: scale(0.5);
  transition: 0.2s;
}

.dotGroup button:hover:not(.carousel__dot--selected) {
  opacity: 0.5;
}

.carousel__dot--selected {
  border: none !important;
  background-color: #bf6d61 !important;
  transform: scale(0.7) !important;
}

.SlideContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
}

.Image {
  margin: auto 5px;
}

@media screen and (min-width: 1700px) {
  .SlideContainer {
    display: flex;
    flex-direction: row-reverse;
  }

  .SlideCarousel {
    width: 80%;
    margin: auto;
  }

  .dotGroup {
    display: block;
  }
}

.carousel {
  max-width: 100%;
}

.SlideCarousel {
  width: 100%;
}

.SlideDesc {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.SlideDesc p {
  text-align: left;
}

p b {
  color: #bf6d61;
}

hr {
  width: 40%;
}

.arrow {
  background: transparent;
  position: fixed;
  padding: 0.5rem;
  /* Arrow size */
  box-shadow: 1px -1px 0 1px #d3aa92 inset;
  -webkit-box-shadow: 2px -2px #d3aa92 inset;
  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  transition: 0.2s;
}

.arrow:hover {
  box-shadow: 2px -2px 0 2px #bf6d61 inset;
  -webkit-box-shadow: 4px -4px #bf6d61 inset;
}

.arrow-up {
  transform: rotate(135deg);
}

.arrow-right {
  transform: rotate(225deg);
}

.arrow-down {
  transform: rotate(-45deg);
}

.arrow-left {
  transform: rotate(45deg);
}

@media screen and (min-width: 700px) {
  .arrow {
    padding: 2rem;
    /* Arrow size */
  }

  .arrow:hover {
    box-shadow: 4px -4px 0 4px #bf6d61 inset;
    -webkit-box-shadow: 8px -8px #bf6d61 inset;
  }
}

.About {
  display: flex;
}

.About img {
  display: none;
}

.text .content {
  padding-top: 50px;
}

@media screen and (min-width: 900px) {
  .About img {
    display: block;
    max-width: 500px;
    border-radius: 50%;
  }

  .text .content {
    padding: 0;
    position: relative;
    left: -100px;
    max-width: 700px;
  }

  .text::before {
    content: '';
    width: 350px;
    height: 100%;
    float: left;
    shape-outside: polygon(-72px -19px,
        10.41% 73px,
        25.11% 31.2%,
        30.68% 47.9%,
        25.75% 65.8%,
        41px 85%,
        -19.69% 101.4%);
    shape-margin: 7%;
  }
}

@media screen and (max-width: 700px) {
  .title .date {
    font-size: 0.9em;
  }
}

@media screen and (min-width: 700px) {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}